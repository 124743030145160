import './styles.css';
import '4finance-chatbot-pl/dist/styles.css';
import * as R from '../../../routes/routesList';

import { useCallback, useEffect, useRef, useState } from 'react';
import ChatBot from '../../../../../assets/icons/chatbot.svg';
import ChatMessageIcon from '../../../../../assets/icons/zaplopl_chatbot_message.svg';
import CloseIcon from '../../../../../assets/icons/close.svg';
import config from '4finance-configuration-pl';
import FinanceChat from '4finance-chatbot-pl';
import SubmitIcon from '../../../../../assets/icons/chat_send_button.svg';
import TypingIcon from '../../../../../assets/icons/chat_typing.svg';
import type { ImmutableMap, TranslateMsg } from '../../../../common/lib/types';

const INACTIVE_TIMEOUT = 60000;
const workingHours = {
  weekdayStart: 8,
  weekdayEnd: 22,
  weekendStart: 8,
  weekendEnd: 20,
};

type Props = {
  msg: TranslateMsg;
  updatePath: (...args: Array<any>) => any;
  latestLoan: ImmutableMap | null | undefined;
  client: ImmutableMap | null | undefined;
  // Own props
  configOverride?: Record<string, any>;
};

const StaticChatbot = (props: Props) => {
  const {
    updatePath,
    configOverride,
  } = props;
  const actionTimeout = useRef();
  const isProduction = config.environment === 'production';
  const [isInactive, setIsInactive] = useState(false);
  const {
    nodeHost,
    marketing,
    storeHistoryInSessionStorage,
    enableTalkToAgent,
    autoStartSettings,
    chatbotFeedback,
  } = configOverride?.chatbot || config.chatbot;
  const goToLogin = useCallback(() => {
    updatePath(R.LOGIN);
  }, []);

  useEffect(() => {
    const onClick = () => {
      if (actionTimeout.current) {
        window.clearTimeout(actionTimeout.current);
      }

      actionTimeout.current = window.setTimeout(() => setIsInactive(true), INACTIVE_TIMEOUT);
    };

    window.document.addEventListener('click', onClick);

    return () => window.document.removeEventListener('click', onClick);
  }, []);

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  return <FinanceChat showLogs={!isProduction} msg={props.msg} goToLogin={goToLogin} nodeHost={nodeHost} latestLoan={props.latestLoan} clientId={props.client?.get?.('id')} openChat={() => {}} enableTalkToAgent={enableTalkToAgent} liveChatSay={() => {}} startConversationText="[start.conversation]" HeaderIcon={ChatBot} OpenIcon={ChatBot} MessageIcon={ChatMessageIcon} SubmitIcon={SubmitIcon} CloseIcon={CloseIcon} TypingIcon={TypingIcon} workingHours={workingHours} autoStartSettings={autoStartSettings} marketingFeatures={marketing.enabled} marketingBannerTimeout={marketing.bannerTimeout} chatbotFeedback={chatbotFeedback} locale="pl" storeHistoryInSessionStorage={storeHistoryInSessionStorage} minuteInactivity={isInactive} />;
};

export default StaticChatbot;
