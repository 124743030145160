import { connectField } from '4finance-onion-form-pl';
import { createValidChars, isRequired, maxLength, minLength, password, regex } from '4finance-validation-pl';
import { MultiField, TextField } from '4finance-components-pl';
import { tidyMobilePhone } from '../../../common/lib/tidy';
import { translate } from '../../../localizations';
import city from '../../../common/validations/city';
import config from '4finance-configuration-pl';
import email from '../../../common/validations/email';
import identityCardNumber from '../../../common/validations/identityCardNumber';
import mobilePhone from '../../../common/validations/mobilePhone';
import notOnlyHyphens from '../../../common/validations/notOnlyHyphens';
import passwordStrict from '@common/validations/passwordStrict';

const fieldProps = {
  autoFocus: true,
  hasIndicator: false,
  bordered: true,
  autocomplete: 'off',
  width: 'full',
};
const ERRORS = {
  errorFeminine: 'required_feminine',
  errorMasculine: 'required_masculine',
  errorPlural: 'required_plural',
  errorSwo: 'required_swo',
  error: 'required',
};
const { lettersAndNumbersAndSpace } = createValidChars('pl');
const isFalsy = isRequired();

function translatedField(name, defaultProps, validations) {
  return FieldComponent => translate(connectField(name, defaultProps, validations)(FieldComponent));
}

export default function isRequiredAltMessage(param = 'error') {
  return (value: any) => {
    const error = isFalsy(value) ? ERRORS[param] : null;

    return error;
  };
}
export const IdentityCardNumber = translatedField('identityCardNumber', {
  type: 'identityCardNumber',
  tidy: ['uppercase'],
  ...fieldProps,
}, [isRequiredAltMessage('errorPlural'), identityCardNumber()])(TextField);
export const Email = translatedField('email', {
  type: 'email',
  ...fieldProps,
}, [isRequiredAltMessage('errorMasculine'), maxLength(90), email()])(TextField);
export const Phone = translatedField('mobilePhone', {
  type: 'tel',
  tidy: [tidyMobilePhone],
  ...fieldProps,
}, [isRequiredAltMessage('errorMasculine'), mobilePhone()])(TextField);
export const Password = translatedField('oldPassword', {
  type: 'password',
  autocomplete: 'off',
  bordered: true,
}, [isRequired(), minLength(7), maxLength(30)])(TextField);
export const NewPassword = translatedField('newPassword', {
  type: 'password',
  autocomplete: 'new-password',
  bordered: true,
}, [isRequired(), minLength(10), maxLength(64), passwordStrict()])(TextField);

export const Street = translatedField('street', {}, [isRequiredAltMessage('errorFeminine'), lettersAndNumbersAndSpace(), notOnlyHyphens(), minLength(3), maxLength(100)])(TextField);
export const House = translatedField('house', {}, [isRequiredAltMessage('errorMasculine'), lettersAndNumbersAndSpace(), notOnlyHyphens(), minLength(1), maxLength(100)])(TextField);
export const Apartment = translatedField('apartment', {
  width: 'xSmall',
}, [lettersAndNumbersAndSpace(), notOnlyHyphens(), minLength(1), maxLength(100)])(TextField);
export const City = translatedField('city', {}, [isRequiredAltMessage('errorFeminine'), city(), minLength(2), maxLength(40)])(TextField);
export const PostalCode = translatedField('postalCode', {
  width: 'small',
}, [isRequiredAltMessage('errorMasculine'), regex(/^\d{2}[ -]?\d{3}$/)])(TextField);

function createVerificationCodeField(name: string) {
  return translatedField(name, {
    delimiter: '',
    showDelimiter: false,
    separated: true,
    fields: [{
      length: 1,
      type: 'tel',
    }, {
      length: 1,
      type: 'tel',
    }, {
      length: 1,
      type: 'tel',
    }, {
      length: 1,
      type: 'tel',
    },
    ...(
      config.standardization && config.standardization.enabled
        ? [
          { length: 1, type: 'tel' },
          { length: 1, type: 'tel' },
        ]
        : []
    )],
  }, [])(MultiField);
}

export const EmailVerificationCode = createVerificationCodeField('emailVerificationCode');
export const PhoneVerificationCode = createVerificationCodeField('phoneVerificationCode');
