import * as R from '@routes/routesList';
import { Classes } from '@common/types';
import { compose } from 'redux';
import { connectActions, connectPrefetch, connectState } from '@browser/connect';
import classnames from 'classnames';
import IconCheck from '@browser/svg/uEA03-check.svg';
// import IconCheck from '@browser/svg/check_new.svg';
import { List, Map } from 'immutable';
import { ReactElement } from 'react';
import { RootState } from '@browser/configureStoreWithHistory';
import { ValuesType } from 'utility-types';
import injectStyles, { Button } from '4finance-components-pl';
import Modal from '../Modal';
import styles from './UpdateDataModal.jss';
import translate from '@localizations/translate';

type RoutePath = ValuesType<typeof R>;

interface CrossCheckErrorsMap<K extends string, V> extends List<Map<'error' | 'edited', boolean>> {
  toJS(): Record<K, V>[];
}

type Props = {
  classes: Classes<typeof styles>;
  crossCheckErrors: CrossCheckErrorsMap<'error' | 'edited', boolean>;
  mobilePhone: string;
  mobilePhoneOnly?: boolean;
  msg: (translationPath: string) => any;
  resetCrossCheckErrors: () => void;
  updatePath: (path: RoutePath, options?: {modal: boolean}) => void;
}

const UpdateDataModal = (props: Props): JSX.Element => {
  const {
    classes,
    crossCheckErrors,
    mobilePhone,
    mobilePhoneOnly,
    msg,
    resetCrossCheckErrors,
    updatePath,
  } = props;

  const handleClose = () => {
    resetCrossCheckErrors();
    updatePath(R.APPLICATION_CONFIRM);
  };

  const goToModal = (url: RoutePath) => updatePath(url);

  const getCrossCheckErrors = (): string[] => {
    // populate object with fields that have error or have been edited
    if (crossCheckErrors) {
      const errorsObject = crossCheckErrors.filter(entry => !!entry?.get('error') || !!entry?.get('edited')).toJS();

      return Object.keys(errorsObject);
    }

    return [];
  };

  const isErrors = !!crossCheckErrors.find(field => !!field?.get('error'));

  const MAPPED_MODAL_ROUTES = {
    mobilePhone: `${R.APPLICATION_CHANGE_PHONE}?from_application=1`,
    email: `${R.APPLICATION_CHANGE_EMAIL}?from_application=1`,
    identityDocument: `${R.APPLICATION_CHANGE_IDENTITY_CARD_NUMBER}?from_application=1`,
    city: `${R.APPLICATION_CHANGE_ADDRESS}?from_application=1`,
    streetAndHouseFlatNumber: `${R.APPLICATION_CHANGE_ADDRESS}?from_application=1`,
    postalCode: `${R.APPLICATION_CHANGE_ADDRESS}?from_application=1`,
  };

  const renderField = (field: string, i: number) => {
    const editedFields = Object.keys(crossCheckErrors.filter(entry => !!entry?.get('edited')).toJS());

    const fieldValue = (props[field as keyof Props] === '***' || !props[field as keyof Props]) ? 'brak danych' : props[field as keyof Props];
    const wasEdited = editedFields.includes(field);
    const buttonText = wasEdited
      ? msg('registration.confirm.cross_check_errors.edited')
      : msg('registration.confirm.cross_check_errors.edit');

    return (
      <li className={classnames([classes.errorItem, fieldValue === 'email' && classes.smallFont])} key={`fieldName-${i}`}>
        <div className={classes.dataWrapper}>
          <p className={classes.label}>{msg(`form.${field}.label`)}</p>
          <p className={classnames(classes.data, wasEdited && classes.correct)}>{fieldValue}</p>
        </div>
        {wasEdited ? (
          <div className={classnames([classes.editButton, classes.editButtonEdited])}>
            <IconCheck />
            <p>{buttonText}</p>
          </div>
        ) : (
          <Button
            className={classes.editButton}
            kind="dark"
            onClick={() => goToModal(MAPPED_MODAL_ROUTES[field as keyof typeof MAPPED_MODAL_ROUTES] as RoutePath)}
          >
            {buttonText}
          </Button>
        )}
      </li>
    );
  };

  const renderPhoneOnly = () => (
    <li className={classes.errorItem}>
      <div className={classes.dataWrapper}>
        <p className={classes.label}>{msg('form.mobilePhone.label')}</p>
        <p className={classes.data}>{mobilePhone}</p>
      </div>

      <Button
        className={classes.editButton}
        kind="dark"
        onClick={() => goToModal(MAPPED_MODAL_ROUTES.mobilePhone as RoutePath)}
      >
        {msg('registration.confirm.cross_check_errors.edit')}
      </Button>
    </li>
  );

  const crossCheckErrorFields = getCrossCheckErrors();

  return (
    <Modal onClose={handleClose} open className={classes.modal}>
      <div className={classes.title}>{msg(`registration.confirm.cross_check_errors.title${!isErrors ? '_all_data_correct' : ''}`)}</div>
      <p className={classes.description}>{msg(`registration.confirm.cross_check_errors.subtitle${!isErrors ? '_all_data_correct' : ''}`)}</p>
      <ul className={classes.errorList}>
        {mobilePhoneOnly ? renderPhoneOnly() : crossCheckErrorFields.map((field, i) => renderField(field, i))}
      </ul>
      <Button className={classes.closeButton} kind="dark" onClick={handleClose}>
        {msg('registration.confirm.cross_check_errors.close')}
      </Button>
    </Modal>
  );
};

UpdateDataModal.styleRoot = 'UpdateDataModal';

export default compose<() => ReactElement>(
  connectActions({
    resetCrossCheckErrors: ['registration', 'resetCrossCheckErrors'],
    updatePath: ['router', 'updatePath'],
  }),
connectState((_props: Props, state: RootState) => {
  const embedded = state.api.getIn(['fetch', 'client', 'query', 'data', '_embedded']);
  const hasEmbedded = !!embedded && embedded.size > 0;

  return {
    crossCheckErrors: ['registration', 'crossCheckErrors'],
    email: ['api', 'fetch', 'client', 'query', 'data', hasEmbedded && '_embedded', 'email', 'email'].filter(Boolean),
    mobilePhone: ['api', 'fetch', 'client', 'query', 'data', hasEmbedded && '_embedded', 'mobilePhone', 'mobilePhone'].filter(Boolean),
    postalCode: ['api', 'fetch', 'client', 'query', 'data', 'declaredAddress', 'postalCode'],
    city: ['api', 'fetch', 'client', 'query', 'data', 'declaredAddress', 'location1'],
    streetAndHouseFlatNumber: ['api', 'fetch', 'client', 'query', 'data', 'declaredAddress', 'location4'],
    identityDocument: ['api', 'fetch', 'client', 'query', 'data', 'identityDocumentId'],
  };
}),
connectPrefetch([
  ['api', 'fetchClient'],
]),
translate,
injectStyles(styles))(UpdateDataModal);
