import { compose } from 'redux';
import { connectActions } from '@browser/connect';
import { useEffect } from 'react';
import { uuidv7 } from '@kripod/uuidv7';
import config from '4finance-configuration-pl';

type Props = {
  setFieldValue: any,
  form: string,
  field: string,
};

const ThreatMetrix = (props: Props): JSX.Element => {
  const {
    setFieldValue,
    form,
    field,
  } = props;
  const {
    profilingDomain,
    orgId,
  } = config.threatmetrix;

  useEffect(() => {
    const sessionId = uuidv7();

    window.tmcommand.execute(profilingDomain, orgId, sessionId);
    setFieldValue(form, field, sessionId);
  }, []);

  return (<></>);
};

export default compose<any>(
  connectActions({
    setFieldValue: ['onionForm', 'setFieldValue'],
  }),
)(ThreatMetrix);
