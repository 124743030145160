import { modalContent } from '../../../shared/components/Modal/Modal.jss';

export default ({ colors, mediaQueries }) => ({
  text: {
    margin: '16px 0',
  },
  button: {
    width: '100%',
  },
  selectContainer: {
    position: 'relative',
  },
  actionButton: {
    border: '0 !important',
    color: colors.primary,
    fontSize: '16px',
    fontWeight: '600',
    width: '100%',
    display: 'block',
    textAlign: 'center',
    backgroundColor: 'transparent',
    marginTop: '24px',
    cursor: 'pointer',
  },
  select: {
    outline: '0',
    width: '100%',
    border: `1px solid ${colors.gray}`,
    color: colors.gray,
    '& option': {
      color: colors.darkGray,
      '&[disabled]': {
        color: colors.gray,
      },
    },
  },
  transfer: {
    '& b': {
      fontWeight: '600',
      marginRight: '4px',
    },
  },
  close: {
    width: '100%',
    margin: '40px 0 16px 0',
  },
  consentsHeading: {
    marginBottom: '32px',
  },
  separator: {
    display: 'none',
    [mediaQueries.breakpointTablet]: {
      display: 'block',
      width: '100%',
      height: '1px',
      backgroundColor: colors.lightGrayLine,
    },
  },
  formWrapper: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    maxWidth: '340px',
    margin: '20px 0 20px 0',
    [mediaQueries.breakpointTablet]: {
      margin: '0 0 20px 0',
      maxWidth: '100%',
      '& form > div > div > input': {
        margin: '0 0 0 0',
      },
    },
    '& form': {
      width: '100%',
    },
  },
  description: {
    width: '100%',
    marginBottom: '10px',
    fontSize: '12px',
    lineHeight: '17px',
    color: colors.gray,
    textAlign: 'left',
    [mediaQueries.breakpointTablet]: {
      marginTop: '26px',
      marginBottom: '23px',
      textAlign: 'left',
    },
  },
  twoFieldsContainer: {
    display: 'flex',
    maxWidth: '320px',
    '& input': {
      width: '158px',
    },
  },

  recaptchaWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    marginTop: '25px',
  },

  modalContentWrapper: { ...modalContent },
});
