import * as C from '../../../../../common/lib/constants';
import * as R from '../../../../routes/routesList';

import { BodyText } from '../../../../shared/components/typography';
import { compose } from 'redux';
import { connectActions, connectPending, connectState } from '../../../../connect';
import { connectSubmit, Form } from '4finance-onion-form-pl';
import { EmailVerificationCode, PhoneVerificationCode } from '../../fields';
import { UPDATE_CLIENT_DATA } from '../../../../../common/settings/actions';
import { useHistory } from 'react-router-dom';
import { useRef, useState } from 'react';
import { useTranslator } from '../../../../../localizations';
import _ from 'lodash';
import Button from '../../../../shared/components/Button';
import classnames from 'classnames';
import config from '4finance-configuration-pl';
import getCaptchaKey from '@browser/lib/getCaptchaKey';
import injectStyles from '4finance-components-pl';
import Modal from '../../../../shared/components/Modal';
import QA_CLASSES from '../../../../lib/qa';
import Reaptcha from 'reaptcha';
import styles from '../Change.jss';

type Props = {
  classes: Record<string, any>;
  sendChangeConfirm: (...args: Array<any>) => any;
  code: string;
  type: string;
  pending: boolean;
  setSettingsRecaptchaResponseToken: (...args: Array<any>) => any;
};
const Submit = connectSubmit(Button);

function getCodeFieldsCount() {
  return config.standardization && config.standardization.enabled ? 6 : 4;
}

const ChangeEmailOrPhoneConfirm = (props: Props): JSX.Element => {
  const {
    classes,
    sendChangeConfirm,
    code,
    type,
    pending,
    setSettingsRecaptchaResponseToken,
  } = props;
  const {
    msg,
  } = useTranslator();
  const history = useHistory();
  const disabled = !code || code.length !== getCodeFieldsCount() || /[^0-9]/.test(code);
  const isComingFromApplicationPage = typeof window !== 'undefined' && new URLSearchParams(window.location.search).get('from_application');
  const formTypes = {
    phone: `${R.CHANGE_PHONE_SUCCESS}?from_application=1`,
    email: `${R.CHANGE_EMAIL_SUCCESS}?from_application=1`,
  };
  const onSubmit = () => {
    if (type === C.PHONE) {
      executeCaptcha();
    } else {
      sendChangeConfirmVariant();
    }
  };

  const sendChangeConfirmVariant = () => (isComingFromApplicationPage ? sendChangeConfirm(formTypes[type] || undefined) : sendChangeConfirm() || undefined);

  const [isCaptchaRendered, setIsCaptchaRendered] = useState(false);

  const captcha = useRef<Reaptcha>();

  const executeCaptcha = () => captcha.current?.execute();

  const onCaptchaRender = () => setIsCaptchaRendered(true);

  const onCaptchaVerify = (responseToken: string) => {
    setSettingsRecaptchaResponseToken(responseToken);
    sendChangeConfirmVariant();
    captcha.current?.reset();
  };

  const changeRoute = () => history.push(isComingFromApplicationPage ? R.APPLICATION_CONFIRM : R.CLIENT_SETTINGS);

  const title = msg(`dashboard.settings.change_${type}.confirm.title`);
  const description = msg(`dashboard.settings.change_${type}.confirm.description`);
  const button = msg(`dashboard.settings.change_${type}.confirm.button`);
  const resend = msg(`dashboard.settings.change_${type}.confirm.resend`);

  return (
    <Modal title={title} open onClose={changeRoute}>
      <BodyText className={classes.text}>
        {description}
      </BodyText>
      <div className={classes.formWrapper}>
        <Form name={'clientSettingsConfirm'} onSubmit={onSubmit} resetOnUnmount>
          {type === C.EMAIL ? <EmailVerificationCode clearApiErrorOnChange /> : <PhoneVerificationCode clearApiErrorOnChange />}
          <Submit
            className={classnames(classes.button, QA_CLASSES[`CLIENT_${type.toUpperCase()}_CONFIRM_BUTTON`])}
            disabled={disabled || pending || (!isCaptchaRendered && type === C.PHONE)}
            loading={pending}
          >
            {button}
          </Submit>
        </Form>
        {type === C.PHONE && (
          <div className={classes.recaptchaWrapper}>
            <Reaptcha
              ref={captcha}
              sitekey={getCaptchaKey()}
              onVerify={onCaptchaVerify}
              onRender={onCaptchaRender}
              size="invisible"
              badge="inline"
            />
          </div>
        )}
      </div>
      <button type="button" className={classes.actionButton}>
        {resend}
      </button>
    </Modal>
  );
};

ChangeEmailOrPhoneConfirm.styleRoot = 'ChangeEmailOrPhoneConfirm';
export default compose<any>(connectState(({
  type,
}: Props) => ({
  code: ['onionForm', 'fields', 'clientSettingsConfirm', `${type}VerificationCode`, 'value'],
})), connectPending({
  pending: UPDATE_CLIENT_DATA,
}), connectActions(({
  type,
}: Props) => ({
  sendChangeConfirm: ['settings', `sendChange${_.capitalize(type)}Confirm`],
  setSettingsRecaptchaResponseToken: ['settings', 'setSettingsRecaptchaResponseToken'],
})), injectStyles(styles))(ChangeEmailOrPhoneConfirm);
