import * as C from '../../../../../common/lib/constants';
import * as R from '../../../../routes/routesList';

import { compose } from 'redux';
import { connectActions, connectPending, connectState } from '../../../../connect';
import { connectSubmit, Form } from '4finance-onion-form-pl';
import { Email, Phone } from '../../fields';
import { SEND_CHANGE_REQUEST } from '../../../../../common/settings/actions';
import { useHistory } from 'react-router-dom';
import { useRef, useState } from 'react';
import { useTranslator } from '../../../../../localizations';
import _ from 'lodash';
import Button from '../../../../shared/components/Button';
import classnames from 'classnames';
import getCaptchaKey from '@browser/lib/getCaptchaKey';
import injectStyles from '4finance-components-pl';
import Modal from '../../../../shared/components/Modal';
import QA_CLASSES from '../../../../lib/qa';
import Reaptcha from 'reaptcha';
import styles from '../Change.jss';

type Props = {
  formValue: string | null | undefined;
  error: (string | null) | null | undefined;
  classes: Record<string, any>;
  // Own props
  formType: string;
  sendChangeRequest: (...args: Array<any>) => any;
  setSettingsRecaptchaResponseToken: (...args: Array<any>) => any;
  pending: any;
  value: string;
};
const Submit = connectSubmit(Button);

const ChangeEmailOrPhone = (props: Props): JSX.Element => {
  const {
    classes,
    error,
    formValue,
    value,
    sendChangeRequest,
    setSettingsRecaptchaResponseToken,
    pending,
    formType,
  } = props;
  const buttonDisabled = error || pending || value === formValue;
  const {
    msg,
  } = useTranslator();
  const history = useHistory();
  const valueRef = useRef('');
  const [isCaptchaRendered, setIsCaptchaRendered] = useState(false);
  const captcha = useRef();

  const isComingFromApplicationPage = typeof window !== 'undefined' && new URLSearchParams(window.location.search).get('from_application');

  const goBack = () => history.push(isComingFromApplicationPage ? R.APPLICATION_CONFIRM : R.CLIENT_SETTINGS);

  const onChangeInput = ({ value: inputValue }: { value: string }) => {
    valueRef.current = inputValue;
  };

  const formTypes = {
    phone: R.CHANGE_PHONE_CONFIRM,
    email: R.CHANGE_EMAIL_CONFIRM,
  };

  const onSubmit = (): void => {
    executeCaptcha();
  };

  const executeCaptcha = () => captcha.current?.execute();

  const onCaptchaRender = () => setIsCaptchaRendered(true);

  const onCaptchaVerify = (responseToken: string) => {
    setSettingsRecaptchaResponseToken(responseToken);
    isComingFromApplicationPage ? sendChangeRequest('clientSettings', `${formTypes[formType]}?from_application=1`) : sendChangeRequest();
    captcha.current?.reset();
  };

  const buttonClasses = classnames(classes.button, QA_CLASSES[`CLIENT_${formType.toUpperCase()}_EDIT_MODAL_BUTTON`], {
    disabled: buttonDisabled,
  });
  const title: string = msg(`dashboard.settings.change_${formType}.change.title`);
  const description: string = msg(`dashboard.settings.change_${formType}.change.description`);
  const button: string = msg(`dashboard.settings.change_${formType}.change.button`);

  return (
    <Modal title={title} open onClose={goBack}>
      <div className={classes.formWrapper}>
        <div className={classes.description}>{description}</div>
        <Form name={'clientSettings'} onSubmit={onSubmit} resetOnUnmount>
          {formType === C.EMAIL ? (
            <Email className={QA_CLASSES.CLIENT_EMAIL_EDIT_INPUT} clearApiErrorOnChange onChange={onChangeInput} />
          ) : (
            <Phone className={QA_CLASSES.CLIENT_PHONE_EDIT_INPUT} clearApiErrorOnChange onChange={onChangeInput} />
          )}
          <Submit type="submit" className={buttonClasses} disabled={buttonDisabled || !isCaptchaRendered} loading={pending}>
            {button}
          </Submit>
        </Form>
        <div className={classes.recaptchaWrapper}>
          <Reaptcha
            ref={captcha}
            sitekey={getCaptchaKey()}
            onVerify={onCaptchaVerify}
            onRender={onCaptchaRender}
            size="invisible"
            badge="inline"
          />
        </div>
      </div>
    </Modal>
  );
};

ChangeEmailOrPhone.styleRoot = 'ChangeEmailOrPhone';
export default compose<any>(connectActions(({
  formType,
}: Props) => ({
  sendChangeRequest: ['settings', `sendChange${_.capitalize(formType)}Request`],
  setSettingsRecaptchaResponseToken: ['settings', 'setSettingsRecaptchaResponseToken'],
  updatePath: ['router', 'updatePath'],
})), connectPending({
  pending: SEND_CHANGE_REQUEST,
}), connectState(({
  formType,
}: Props) => {
  const type = formType === 'email' ? formType : 'mobilePhone';

  return {
    error: ['onionForm', 'fields', 'clientSettings', type, 'error'],
    formValue: ['onionForm', 'fields', 'clientSettings', type, 'value'],
    value: ['api', 'fetch', 'client', 'query', 'data', type, type],
  };
}), injectStyles(styles))(ChangeEmailOrPhone);
